import React from "react"
import NotificationSystem from "react-notification-system"
import * as axios from "axios"
import logo from "../images//logo.png"
import "../stylesheets/custom.css"
import ReactGA from "react-ga"
import MessengerCustomerChat from "react-messenger-customer-chat"

let _notificationSystem = undefined

export default class All extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
    }
  }

  onSubmit = () => {
    axios({
      method: "POST",
      url: `/.netlify/functions/early-bird`,
      data: JSON.stringify({ email: this.state.email }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(result => {
        _notificationSystem.addNotification({
          title: "You're in the loop",
          message: "When we launch, you'll be among the first to know.",
          autoDismiss: 0,
          level: "success",
          position: "tr",
          dismissible: "button",
        })
      })
      .catch(err => {
        _notificationSystem.addNotification({
          title: "This is embarrassing",
          message: "Something went wrong we didn't get your email.",
          autoDismiss: 0,
          level: "error",
          position: "tr",
          dismissible: "button",
        })
      })
  }

  componentDidMount = () => {
    _notificationSystem = this.refs.notificationSystem

    ReactGA.initialize("UA-117105247-8")
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  handleChange = event => {
    this.setState({
      email: event.currentTarget.value,
    })
  }

  render() {
    return (
      <>
        <section className="hero">
          <div className="container text-center">
            <div className="row">
              <div className="col-md-12">
                <a className="hero-brand" href="/" title="Home">
                  <img alt="Logo" src={logo} />
                </a>
                <p style={{ color: "white" }}>
                  Cherries is a new desktop application that lets you manage
                  your tv shows and episodes.
                  <br />
                  Easily download the subtitles for the episodes, get
                  informations on the cast, synchronize your progress with your
                  differents accounts (
                  <a href="https://www.tvtime.com">TV Time</a>,{" "}
                  <a href="https://trakt.tv/">Trakt</a>,{" "}
                  <a href="https://simkl.org/">Simkl</a>...) and many other
                  features...
                  <br />
                  <br />
                  Sign up to be the first to know when the app becomes
                  available.
                </p>
              </div>
            </div>

            <div className="col-md-12">
              <h1>Coming soon</h1>

              <p className="tagline" />
              <button
                type="button"
                className="btn btn-main"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              >
                Sign up for the release
              </button>
            </div>
          </div>
        </section>

        <div
          className="modal fade"
          id="exampleModalCenter"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Be the first to know when we release the app
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  autoFocus
                  onChange={this.handleChange}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.onSubmit.bind(this)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref="notificationSystem" />
        <MessengerCustomerChat
          pageId="838815296456049"
          appId="0"
          themeColor="#F1504F"
        />

        <footer className="site-footer" style={{ display: `none` }}>
          <div className="bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-xs-12 text-lg-left text-center">
                  <p className="copyright-text">
                    © {new Date().getFullYear()}{" "}
                    <a href="https://github.com/jamelait/">jamelait</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <a className="scrolltop" href="/#">
          <span className="fa fa-angle-up" />
        </a>
      </>
    )
  }
}
