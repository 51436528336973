import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import All from "../components/all"

import "../stylesheets/style.css"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Cherries | TV show manager"
      keywords={[
        `cherries`,
        `application`,
        `tv`,
        `series`,
        `manager`,
        `desktop`,
        `synchronize`,
      ]}
    />
    <All />
  </Layout>
)

export default IndexPage
